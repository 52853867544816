import styled from '@emotion/styled'
import tw from 'twin.macro'

export const TestimonialSectionStyled = styled.section`
  h2 {
    ${tw`text-center mb-12`}
  }
  
  .with-subhead {
    ${tw`mb-24 text-center`}
    
    h2 {
      ${tw`mb-4`}
    }
  }

  ul {
    ${tw`grid lg:grid-cols-2 gap-y-16 lg:gap-x-8`}
    li {
      h3 {
        ${tw`md:mb-2`}
      }
    }
  }
`
