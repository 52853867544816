import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import SEO from '../../components/SEO'
import Hero from '../../components/Hero/HeroWithLockup'
import {
  ContentfulFeatureContent,
  ContentfulList,
  ContentfulMedia,
  ContentfulPage,
  ContentfulService,
  ContentfulTestimonial,
  ContentfulTestimonials,
  ContentfulText,
} from '../../../types/graphql-types'
import Text from '../../components/Text'
import ServicesCard from '../../components/Cards/ServicesCard'
import Testimonial from '../../components/Testimonial'
import { TestimonialSectionStyled } from '../../styles/testimonials-styles'
import Layout from '../../components/Layout/Spanish/Layout'
import { phoneNumber } from '../../utils/phoneNumber'

interface Props {
  data: {
    contentfulPage: ContentfulPage
  }
}

const HeroStyled = styled(Hero)`
  .hero-image {
    bottom: 0;
  }

  @media (min-width: 1024px) {
    .hero-image {
      bottom: -75px;
    }
  }

  @media (min-width: 1280px) {
    .hero-image {
      bottom: -100px;
      width: auto;
    }
  }
  
  p {
    ${tw`mb-4 last:text-lg`}
  }
`
const StickyBar = styled.section`
  ${tw`block w-full bg-white sticky top-0 z-50 lg:(border-t-0 max-w-full shadow)`}

  a {
    ${tw`lg:hidden flex no-underline hover:no-underline text-black`}
  }
`

const IntroCopyContainer = styled.div`
  ${tw`block w-full text-lg mb-4 pr-6 lg:text-center`}
  h2 {
    ${tw`md:mb-6`}
  }
`
const PlanCTAContainer = styled.div`
  ${tw`lg:text-center`}
  h2 {
    ${tw`md:mb-6`}
  }
  a {
    ${tw`rounded-md border bg-bby-blue-700 px-4 py-2 text-center text-bby-charcoal-50 no-underline hover:bg-blue-600 hover:text-bby-charcoal-50 hover:no-underline active:bg-bby-blue-800 active:text-bby-charcoal-50`}
  }
`

export const query = graphql`
  query ServiciosLanding {
    contentfulPage(
      externalName: { eq: "servicios" }
      node_locale: { eq: "es-US" }
    ) {
      title
      seoTitle
      seoDescription
      phoneNumberCopy
      slug
      externalName
      references {
        ...hero
        ... on ContentfulFeatureContent {
          name
          id
          externalName
          references {
            ... on ContentfulMedia {
              __typename
              name
              externalName
              id
              media {
                file {
                  url
                }
                gatsbyImageData
              }
            }
            ... on ContentfulText {
              name
              externalName
              id
              shortSimpleText
              longSimpleText {
                childMarkdownRemark {
                  html
                }
              }
              richText {
                __typename
                raw
                references {
                  ... on ContentfulMedia {
                    id
                    name
                    __typename
                    contentful_id
                    externalName
                    shortSimpleText
                    youTubeId
                    altText
                  }
                  ... on ContentfulLink {
                    id
                    name
                    __typename
                    contentful_id
                    externalName
                    ctaLink
                    displayName
                  }
                }
              }
              link {
                id
                name
                ctaLink
              }
            }
          }
        }
        ... on ContentfulTestimonials {
          id
          title
          externalName
          testimonials {
            id
            __typename
            heading
            copy {
              childMarkdownRemark {
                html
              }
            }
            author
          }
        }
        ... on ContentfulList {
          name
          externalName
          id
          items {
            ... on ContentfulService {
              name
              class
              icon
              headline
              slug
              body {
                childrenMarkdownRemark {
                  html
                }
              }
              featuredImage {
                title
                description
                id
                file {
                  url
                }
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

const ServiciosLandingPage = ({ data }: Props): JSX.Element => {
  return (
    <Layout
      {...(data.contentfulPage?.phoneNumberCopy && {
        phoneNumberCopyField: data.contentfulPage?.phoneNumberCopy,
      })}
    >
      <SEO
        title={data.contentfulPage.seoTitle as string}
        description={data.contentfulPage.seoDescription}
      />
      <HeroStyled
        className="hero-with-image"
        copyClass="sm:col-span-6"
        imageClass="hero-image absolute md:right-0 left-1/2 -translate-x-1/2 transform md:left-auto md:transform-none w-40 sm:w-60 md:w-80 lg:w-96"
        data={data}
      />
      {data.contentfulPage.references?.map(content => {
        const featureContent = content as ContentfulFeatureContent
        const list = content as ContentfulList
        const testimonials = content as ContentfulTestimonials

        // Sticky Bar
        if (featureContent.externalName === 'Sticky Bar') {
          return (
            <StickyBar key={featureContent.id} className="sticky-header h-16">
              <div className="container flex h-full">
                <div className="hidden w-auto items-center lg:flex lg:w-full">
                  {(featureContent.references as ContentfulMedia[]).map(
                    image => {
                      if (image.externalName === 'Brand Logo') {
                        return (
                          <img
                            className="h-8"
                            key={image.id}
                            alt={(image.altText as string) + ' logo'}
                            src={
                              image.media?.file?.url ? image.media.file.url : ''
                            }
                          />
                        )
                      }
                    }
                  )}
                </div>
                <div className="flex w-full items-center lg:justify-end">
                  <span className="flex">
                    {(featureContent.references as ContentfulText[]).map(
                      copy => {
                        if (copy.externalName === 'Feature Content Copy') {
                          return (
                            <span className="text-lg" key={copy.id}>
                              {copy.shortSimpleText}
                            </span>
                          )
                        }
                      }
                    )}
                    <strong className="ml-1 text-lg">
                      <a href={`tel:+1-${phoneNumber()}`} role="button">
                        {phoneNumber()}
                      </a>
                      <span className="hidden lg:flex">{phoneNumber()}</span>
                    </strong>
                  </span>
                </div>
              </div>
            </StickyBar>
          )
        }

        // Intro Section
        if (featureContent.externalName === 'Feature Content 1') {
          return (
            <section className="content-section" key={featureContent.id}>
              {(featureContent.references as ContentfulText[]).map(copy => {
                if (copy.externalName === 'Feature Content Copy') {
                  return (
                    <IntroCopyContainer key={copy.id}>
                      <Text rich={copy} />
                    </IntroCopyContainer>
                  )
                }
              })}
            </section>
          )
        }

        // Services Sections
        if (list.externalName === 'Services') {
          return (
            <section key={list.id} className="content-section pt-16">
              <h2 className="mb-8 text-center">{list.name}</h2>
              <div className="block">
                {(list.items as ContentfulService[]).map(item => (
                  <ServicesCard
                    key={item.name}
                    item={item}
                    className={`block`}
                  />
                ))}
              </div>
            </section>
          )
        }

        // Plans CTA Section
        if (featureContent.externalName === 'Feature Content 2') {
          return (
            <section className="content-section" key={featureContent.id}>
              {(featureContent.references as ContentfulText[]).map(copy => {
                if (copy.externalName === 'Feature Content Copy') {
                  return (
                    <PlanCTAContainer key={copy.id}>
                      <Text long={copy} />
                    </PlanCTAContainer>
                  )
                }
              })}
            </section>
          )
        }

        // Testimonials Section
        if (testimonials.externalName === 'Testimonials') {
          return (
            <TestimonialSectionStyled
              key={testimonials.id}
              className="content-section-py lg:pt-16"
            >
              <h2>{testimonials.title}</h2>
              <ul>
                {(testimonials.testimonials as ContentfulTestimonial[]).map(
                  testimonial => {
                    return (
                      <li key={testimonial.id}>
                        <Testimonial
                          heading={testimonial.heading}
                          copy={testimonial.copy}
                          author={testimonial.author}
                        />
                      </li>
                    )
                  }
                )}
              </ul>
            </TestimonialSectionStyled>
          )
        }
      })}
    </Layout>
  )
}

export default ServiciosLandingPage
