import React from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import quoteImage from '../../images/circle-quote.svg'
import { ContentfulTestimonial } from '../../../types/graphql-types'

const TestimonialStyled = styled.figure`
  ${tw`relative border border-bby-lt-gray p-12 bg-white`}
  border-radius: 5px;

  img {
    ${tw`absolute z-[45] -translate-x-1/2 -translate-y-1/2`}
    left: 40%;
    top: -36px;
  }

  blockquote h3 {
    ${tw`text-2xl`}
  }

  figcaption span {
    ${tw`font-bold`}
  }

  @media (min-width: 768px) {
    img {
      left: 45%;
    }
  }
`

const Testimonial: React.FC<
  Pick<ContentfulTestimonial, 'heading' | 'copy' | 'author'>
> = ({ heading, copy, author }) => (
  <TestimonialStyled>
    <img src={quoteImage} alt="" />
    <blockquote>
      <h3>{heading as string}</h3>
      <div
        key={copy?.id}
        className="md:h-32 lg:h-44 xl:h-36"
        dangerouslySetInnerHTML={{
          __html: copy?.childMarkdownRemark?.html as string,
        }}
      />
    </blockquote>
    <figcaption>
      <span>{author as string}</span>
    </figcaption>
  </TestimonialStyled>
)

export default Testimonial
